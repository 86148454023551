import { defineStore } from 'pinia'
import { DirectusModule, fractionFieldsFilter } from '~/types/enums'
import type { Fraction } from '~/types/types'

export const useFractionStore = defineStore('fraction-store', () => {
  const fractions = ref<Fraction[] | null>(null)

  async function getFractionData() {
    if (fractions.value !== null) return

    try {
      const { $directus, $readItems } = useNuxtApp()

      const { data: fractionResponse } = await useAsyncData(DirectusModule.Fraction, () => {
        return $directus.request(
          $readItems(DirectusModule.Fraction as any, {
            fields: fractionFieldsFilter,
          }),
        )
      })

      if (!fractionResponse.value)
        throw createError({
          status: 404,
          statusText: 'Page Not Found',
        })

      fractions.value = fractionResponse.value as Fraction[]
    } catch (error) {
      console.error('Error fetching fraction data: ', error)
    }
  }
  const fractionsSorted = computed(() => {
    if (!fractions.value) return null

    const singletonFractions = fractions.value.filter(fraction => fraction.has_children === false)

    return singletonFractions.slice().sort((a, b) => a.favorite_order - b.favorite_order)
  })

  function getFractionById(id: number) {
    if (!fractions.value) return null

    return fractions.value.find(fraction => fraction.id === id)
  }

  return {
    fractions,
    getFractionData,
    getFractionById,
    fractionsSorted,
  }
})
